<!--
 * @Author: liujian
 * @Description: 
 * @Date: 2021-09-02 10:51:14
 * @LastEditTime: 2023-09-25 17:48:54
 * @FilePath: /three-class-fe-1/src/components/common/LoginPopup.vue
-->
<template>
    <div :class="state.showMask ? 'login-popup mask' : 'login-popup'">
        <div class="login-wrapper">
            <el-icon v-if="state.showMask" class="el-icon-close close iconfont icon-a-96" @click="closeLoginPopup"></el-icon>
            <div class="name">梓才网</div>
            <div class="login-form">
                <ElForm
                    :model="state.userInfo"
                    :rules="rules"
                    ref="loginForm"
                    label-position="top"
                    label-width="80px"
                    status-icon
                    @keyup.enter="submit"
                    v-show="state.mode === 'login'"
                >
                    <ElFormItem label="登录账号" prop="mobile">
                        <ElInput
                            v-model="state.userInfo.mobile"
                            placeholder="请输入注册手机号"
                            autocomplete="off"
                            :class="state.mobileValidateSuccess ? 'validate-success' : ''"
                        ></ElInput>
                    </ElFormItem>
                    <ElFormItem label="登录密码" prop="password">
                        <ElInput
                            v-model="state.userInfo.password"
                            placeholder="请输入登录密码"
                            type="password"
                            :class="state.passwordValidateSuccess ? 'validate-success' : ''"
                        ></ElInput>
                    </ElFormItem>
                    <ElFormItem size="large">
                        <ElButton type="primary" @click="submit">登录</ElButton>
                        <span class="change-mode-span">
                            <el-popover
                                placement="bottom"
                                :width="200"
                                trigger="click"
                                content="密码已重置为“111111”，未注册请先注册账号。"
                            >
                                <template #reference>
                                <span>忘记密码?</span>
                                </template>
                            </el-popover>
                            <span @click="toRegister">立即注册</span> 
                        </span>
                    </ElFormItem>
                </ElForm>
                
                <ElForm
                    :model="state.registerInfo"
                    :rules="registerRules"
                    ref="registerForm"
                    label-position="right"
                    label-width="95px"
                    status-icon
                    @keyup.enter="register"
                    v-show="state.mode === 'register'">
                    <el-form-item label="真实姓名 :" prop="name" required>
                        <el-input
                            v-model.trim="state.registerInfo.name"
                            placeholder="请输入学生真实姓名"
                            maxlength="10"
                        ></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="账号 :" prop="account" required>
                        <el-input
                            v-model.trim="state.registerInfo.account"
                            placeholder="请输入账号"
                            maxlength="11"
                        ></el-input>
                    </el-form-item> -->
                    <el-form-item label="手机号码 :" prop="phone" required>
                        <el-input
                            v-model.trim="state.registerInfo.phone"
                            placeholder="请输入手机号码"
                            maxlength="11"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="账户密码 :" prop="password" required>
                        <el-input
                            v-model.trim="state.registerInfo.password"
                            placeholder="请输入账户密码"
                            maxlength="12"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="身份证号码 :" prop="id_card" required>
                        <el-input
                            v-model.trim="state.registerInfo.id_card"
                            placeholder="请输入身份证号码"
                            maxlength="18"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="性别 :" prop="sex" required>
                        <el-radio-group v-model="state.registerInfo.sex">
                            <el-radio :label="1">男</el-radio>
                            <el-radio :label="2">女</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="民族 :" prop="nation" required>
                        <el-input
                            v-model.trim="state.registerInfo.nation"
                            placeholder="请输入民族"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="地区 :" prop="area_id" required>
                        <el-cascader
                            :options="state.provinces"
                            clearable
                            :props="cascaderProp"
                            v-model="state.areaTree"
                            @change="onChangeArea"
                        />
                    </el-form-item>
                    <el-form-item label="所属学校 :" prop="school" required>
                        <el-select
                            v-model="state.registerInfo.school"
                            filterable
                            allow-create
                            reserve-keyword
                            default-first-option
                            placeholder="请选择学校"
                        >
                            <el-option
                                v-for="item in state.schoolList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name"
                            />
                        </el-select>
                        <span class="grade-tips">若下拉菜单中未找到所在学校，请手动输入学校名称</span>
                    </el-form-item>
                    <el-form-item label="年级 :" prop="grade" required>
                        <el-select v-model="state.registerInfo.grade" class="m-2" placeholder="请选择年级">
                            <el-option
                            v-for="item in state.gradeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="班级 :" prop="class_name" required>
                        <el-input
                            v-model.trim="state.registerInfo.class_name"
                            placeholder="请输入班级"
                        ></el-input>
                    </el-form-item>
                    <el-form-item size="large" label-width='0px'>
                        <ElButton type="primary" @click="register">注册</ElButton>
                        <span @click="toRegister" class="change-mode-span">立即登录</span>
                    </el-form-item>
                </ElForm>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent, onMounted, reactive, ref } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { RouterName } from '@/router/constant'
    import { loginVerify } from '@/service/api/login'
    import { useLoginPopupStoreWithOut } from '@/store/modules/loginPopup'
    import { useUserStoreWithOut } from '@/store/modules/user'
    import { useCourseAuthVerify } from '@/common/hooks/courseAuthVerify'
    import { LOCAL_USER_INFO } from '@/config/constant'
    import clickOutside from '@/common/directives/clickOutside'
    import { getUserInfo, getAreaTree, getSchoolList, getGradeList, createUser } from '@/service/api/user'
    import { ElForm, ElFormItem, ElInput, ElButton, ElMessage } from 'element-plus'
    import { passwordValid, checkPhone, accountValid, isChineseIdCard } from '@/utils/validate'
    

    export default defineComponent({
    name: 'LoginPopup',
    props: {
        willRouteName: {
            type: String,
            default: RouterName.HOME
        },
        showMask: {
            type: Boolean,
            default: true
        }
    },
    directives: {
        clickOutside
    },
    setup(props) {
        const route = useRoute();
        const router = useRouter();
        const loginPopupStore = useLoginPopupStoreWithOut();
        const userInfoStore = useUserStoreWithOut();
        const LOGIN = 'login';
        const REGISTER = 'register';
        const state = reactive({
            // 账号、密码
            userInfo: {
                mobile: '',
                password: ''
            },
            // 是否显示阴影
            showMask: props.showMask,
            // 账户验证成功
            mobileValidateSuccess: false,
            // 密码验证成功
            passwordValidateSuccess: false,
            mode: LOGIN,
            registerInfo: {
                account: '',
                name: '',
                password: '',
                sex: 1,
                nation: '',
                area_id: '',
                school: '',
                grade: '',
                class_name: '',
                id_card: '',
                phone: '',
                province_id: '',
                city_id: '',
            },
            provinces: [] as any[],
            areaTree: [] as any[],
            schoolList: [] as any[],
            gradeList: [] as any[],
            canCreateSchool: false,
        });
        /**
         * 验证账号
         */
        const validatePhone = (rule: {}, value: string, callback: Function) => {
            if (!value) {
                state.mobileValidateSuccess = false;
                return callback(new Error('账号不能为空'));
            }
            else {
                state.mobileValidateSuccess = true;
                callback();
            }
        };
       const registerRules = {
            account: [
                { validator: accountValid, trigger: 'change' },
                {
                    required: true,
                    message: '请输入账号',
                    trigger: 'blur'
                }
            ],
            nation: [
                { required: true, message: '请输入民族', trigger: 'change' },
                {
                    required: true,
                    message: '请输入民族',
                    trigger: 'blur'
                }
            ],
            password: [
                { validator: passwordValid, trigger: 'change' },
                {
                    required: true,
                    message: '请输入密码',
                    trigger: 'blur'
                }
            ],
            name: [
                { required: true, message: '请输入真实姓名', trigger: 'blur' },
                {
                    required: true,
                    message: '请输入真实姓名',
                    trigger: 'change'
                }
            ],
            grade: [
                { required: true, message: '请选择年级', trigger: 'change' },
                {
                    required: true,
                    message: '请选择年级',
                    trigger: 'blur'
                }
            ],
            class_name: [
                { required: true, message: '请输入班级', trigger: 'blur' },
                {
                    required: true,
                    message: '请输入班级',
                    trigger: 'change'
                }
            ],
            school: [
                { required: true, message: '请选择学校', trigger: 'change' },
                {
                    required: true,
                    message: '请选择学校',
                    trigger: 'blur'
                }
            ],
            area_id: [{ required: true, message: '请选择所属地区', trigger: 'change' }],
            phone: [{ validator: checkPhone, trigger: 'blur' }, { required: true, message: '请输入手机号码', trigger: 'change' }],
            id_card: [{ validator: isChineseIdCard, trigger: 'blur' }, {
                required: true,
                message: '请输入身份证号码',
                trigger: 'change'
            }]
        }
        /**
         * 验证登录密码
         */
        const validatePassword = (rule: {}, value: string, callback: Function) => {
            if (!value) {
                state.passwordValidateSuccess = false;
                return callback(new Error('密码不能为空'));
            }
            else {
                state.passwordValidateSuccess = true;
                callback();
            }
        };
        /**
         * login form ref
         */
        const loginForm = ref<{
            validate: Function;
            clearValidate: Function;
        }>({
            validate: (valid: boolean) => { },
            clearValidate: () => { }
        });
        /**
         * 登录校验规则
         */
        const rules = {
            mobile: [{ min: 11, max: 11, validator: validatePhone, trigger: 'blur' }],
            password: [{ required: true, trigger: 'blur', validator: validatePassword }]
        };
        /**
         * 当前路径是否是登录页
         */
        const verifyLoginPath = () => {
            return route.name === RouterName.LOGIN;
        };
        /**
         * 登录
         */
        const submit = () => {
            loginForm.value &&
                loginForm.value.validate(async (valid: boolean) => {
                    if (!valid) {
                        return;
                    }
                    const params = {
                        account: state.userInfo.mobile,
                        password: state.userInfo.password
                    };
                    const res = await loginVerify(params);
                    if (res) {
                        const userInfoRes = await getUserInfo();
                        const userInfo = {
                            userId: userInfoRes.user_id,
                            role: userInfoRes.role_id,
                            realName: userInfoRes.name,
                            avatar: userInfoRes.cover_url,
                            isAdmin: userInfoRes.is_admin,
                            isManage: userInfoRes.is_manage,
                            isStudent: userInfoRes.is_student,
                            isTeacher: userInfoRes.is_teacher
                        };
                        userInfoStore.setUserInfo(userInfo);
                        loginPopupStore.setShowLoginPopup(false);
                        if (verifyLoginPath()) {
                            router.push({ name: props.willRouteName });
                        }
                        else {
                            const courseId = loginPopupStore.getCourseId;
                            if (courseId) {
                                useCourseAuthVerify(props.willRouteName as RouterName, loginPopupStore.getWillPathParams, router, courseId, true);
                            }
                            else {
                                router.push({ name: props.willRouteName, params: loginPopupStore.getWillPathParams });
                            }
                        }
                    }
                    loginForm.value.clearValidate();
                });
        };
        /**
         * 关闭登录的弹框
         */
        const closeLoginPopup = () => {
            loginPopupStore.clearLoginPopupInfo();
        };
        const toRegister = () => {
            state.mode = state.mode === LOGIN ? REGISTER : LOGIN;
        }
        const cascaderProp = {
            label: 'name',
            value: 'id',
            checkStrictly: false,
        }
        const onChangeArea = (value: any) => {
            state.areaTree = value ? value : [null, null, null]
            state.registerInfo.province_id = value ? value[0] : ''
            state.registerInfo.city_id = value ? value[1] : ''
            state.registerInfo.area_id = value ? value[2] : ''
            state.registerInfo.school = ''
            if(value) {
                getSchool()
            }
        }
        const getProvinces = () => {
            getAreaTree().then((res: any) => {
                state.provinces = res || []
            })
        }
        const getSchool = (query?: string) => {
            getSchoolList({
                name: query,
                page_size: 10000,
                province_id: state.registerInfo.province_id,
                city_id: state.registerInfo.city_id,
                area_id: state.registerInfo.area_id
            }).then((res: any) => {
                state.schoolList = res || []
                state.canCreateSchool = !state.schoolList.length
            })
        }
        const getGrade = () => {
            getGradeList().then((res: any) => {
                state.gradeList = res || []
            })
        }
        const registerForm = ref<{
            validate: Function;
            clearValidate: Function;
        }>({
            validate: (valid: boolean) => { },
            clearValidate: () => { }
        })
        const register = () => {
            state.registerInfo.account = state.registerInfo.phone
            registerForm.value && registerForm.value.validate((valid: boolean) => {
                if(!valid) return
                createUser(state.registerInfo).then((res: any) => {
                    if(res) {
                        ElMessage.success('注册成功，请登录')
                        state.mode = LOGIN
                    }
                })
            })
        }
        onMounted(()=>{
            getProvinces()
            getGrade()
        })
        return {
            state,
            rules,
            loginForm,
            submit,
            closeLoginPopup,
            toRegister,
            cascaderProp,
            onChangeArea,
            registerRules,
            register,
            registerForm,
        };
    },
    components: { ElForm }
})
</script>

<style scoped lang="scss">
    .login-popup {
        z-index: 2;
        &.mask {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(#000, 0.6);
        }
        .login-wrapper {
            width: 28%;
            // height: 36%;
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 350px;
            max-width: 600px;
            min-height: 400px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            transform: translate(-50%, -50%);
            padding: 35px 32px;
            border-radius: 15px;
            background: #fff;
            box-shadow: 0px 0px 35px rgba($color: #888, $alpha: 0.4);
            .name {
                font-size: 34px;
                font-weight: 600;
                color: #419eff;
            }
            .grade-tips {
                font-size: 12px;
                color: #f33000;
            }
            .login-form {
                width: 100%;
                :deep .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
                    display: none;
                }
                :deep .el-input__suffix {
                    color: #2ec4b6;
                    right: -7px;
                }
                :deep .el-input {
                    border-bottom: 1px solid #a9a9a9;
                    &.validate-success {
                        border-bottom: 1px solid #3a86ff;
                    }
                }
                :deep .el-input__inner {
                    border: none;
                    border-radius: 0;
                    padding-left: 0px;
                }
                :deep .el-form-item__label {
                    font-size: 14px;
                    font-weight: 400;
                    color: #5f6972;
                }
                :deep .el-button {
                    width: 100%;
                    margin-top: 18px;
                }
                :deep .el-cascader, .el-select {
                    width: 100%;
                }
                .change-mode-span {
                    display: inline-block;
                    width: 100%;
                    // text-align: right;
                    display: flex;
                    justify-content: space-between;
                    >span {
                        cursor: pointer;
                    }
                }
            }
        }
        .close {
            position: absolute;
            right: 16px;
            top: 16px;
            font-size: 14px;
            color: #a9a9a9;
            cursor: pointer;
        }
    }
</style>
